<template>
    <div class="flex w-full h-full items-center justify-center">
        <div class="progress-circular">
            <svg viewBox="25 25 50 50">
                <circle class="progress-circular__path" cx="50" cy="50" r="20" fill="none" stroke-width="5" />
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LoadingSpinner',
    data() {
        return {

        };
    },
};
</script>

<style scoped>
.progress-circular {
    display: inline-block;
    width: 40px;
    height: 40px;
}

.progress-circular svg {
    animation: rotate 2s linear infinite;
}

.progress-circular__path {
    stroke: #409eff;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -40px;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -120px;
    }
}
</style>
